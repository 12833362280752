<template>
  <div class="fix-height home__top-banner">
    <client-only>
      <div v-if="bannerData && bannerData.length" class="hero-banner">
        <Swiper ref="banner" v-bind="swiperOption" class="mySwiper">
          <SwiperSlide v-for="(item, idx) in bannerData" :key="item.id" class="swiper-slide">
            <BannerItem :item="item" :index="idx" @click="handleClickBanner(item)" />
          </SwiperSlide>
        </Swiper>
      </div>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Pagination, EffectCoverflow, Navigation } from 'swiper'
import type { SwiperOptions } from 'swiper'
import BannerItem from './banner-item'
import useHeroBanner from '~/composables/home/useHeroBanner'

const { bannerData, fetchBanner, handleClickBanner } = useHeroBanner()
const swiperOption: SwiperOptions = reactive({
  grabCursor: true,
  initialSlide: 0,
  observer: true,
  loop: true,
  slidesPerView: 1,
  slidesPerGroup: 1,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  centeredSlides: true,
  spaceBetween: 0,
  pagination: {
    clickable: true
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  modules: [Autoplay, Pagination, EffectCoverflow, Navigation]
})

onMounted(() => {
  fetchBanner()
})
</script>

<style scoped lang="scss">
@import 'assets/scss/components/desktop/pages/home/hero-banner/index.scss';
</style>
