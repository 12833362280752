<template>
  <nuxt-link id="news-section-item" :to="`${PAGE_URLS.NEWS}/${item.alias}`" class="news-section-item">
    <div class="news-section-item__content">
      <div class="news-section-item__content--title" v-html="item.title" />
      <div class="news-section-item__content--desc" v-html="item.desc" />
    </div>
    <div class="news-section-item__image">
      <BaseImg :id="item.title" :src="item?.thumbnail" :alt="item.title" />
    </div>
    <BaseButton class="btn" @click="router.push(`${PAGE_URLS.NEWS}/${item.alias}`)">Chi Tiết</BaseButton>
  </nuxt-link>
</template>

<script setup>
import dayjs from 'dayjs'
import BaseButton from '~/components/common/base-button.vue'
import { PAGE_URLS } from '~/config/page-url'

const router = useRouter()
const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  item: {
    type: Object,
    default: () => {}
  }
})

const getTime = () => {
  const time = dayjs(props.item?.created_time)
  return time ? `${time.format('hh')}h${time.format('mm')} Ngày ${time.format('DD/MM')}` : ''
}
</script>
<style scoped lang="scss">
@import 'assets/scss/components/desktop/pages/home/news/news-section-item.scss';
</style>
