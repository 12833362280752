<template>
  <div class="jackpot-number">
    <div class="container">
      <JackpotAnimatedNumber
        v-for="(item, index) in list"
        :key="Math.random() + index"
        :number="item"
        :current-index="index"
        :from-number="index === 0 ? createFromNumber(item) : 0"
        :show-comma="index !== list.length - 1 && index !== list.length - 2"
      />
    </div>
  </div>
</template>
<script setup>
import JackpotAnimatedNumber from '~/components/common/jackpot-animated-number.vue'
const props = defineProps({
  number: {
    type: String,
    default: '0'
  }
})

const createFromNumber = (number) => {
  if (number < 10) {
    return 0
  } else if (number < 100) {
    return 10
  } else {
    return 100
  }
}

const list = computed(() => {
  return splitStringIntoChunksFromEnd(props.number)
})

function splitStringIntoChunksFromEnd(str, chunkSize = 3) {
  const result = []
  for (let i = str.length; i > 0; i -= chunkSize) {
    const start = Math.max(0, i - chunkSize)
    result.push(str.slice(start, i))
  }
  result.unshift('VND')
  return result.reverse()
}
</script>

<style lang="scss" scoped src="assets/scss/components/desktop/pages/home/jackpot/jackpot-number.scss"></style>
