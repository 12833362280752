<template>
  <div
    class="banner-item"
    @click="handleClick()"
  >
    <BaseImg :src="item.img" :alt="item?.name" lazy />
  </div>
</template>

<script setup lang="ts">
import { IBannerItem } from '~/types/home'
import BaseImg from '~/components/common/base-img.vue'

withDefaults(defineProps<{ item: Partial<IBannerItem> }>(), {
  item: () => ({
    img: '',
    name: ''
  })
})

const emit = defineEmits(['click'])

const handleClick = () => {
  emit('click')
}
</script>

<style lang="scss" scoped src="assets/scss/components/desktop/pages/home/hero-banner/banner-item.scss" />
