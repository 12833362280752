<template>
  <div class="live-casino">
    <HeaderSection
      title="LIVE CASINO"
      :url-section="`${LOBBY_CASINO_URLS.ROOT}`"
      text-url="Xem tất cả"
      :is-hide-light-arrow="false"
      class="container"
    />
    <div class="frame container">
      <div class="background-image" />
    </div>
    <div class="swiper-container container">
      <div class="swiper-button-prev" />
      <Swiper v-bind="swiperOption" class="swiper" @slide-change="handleSlideChange">
        <SwiperSlide v-for="(item, index) in liveCasinoList" :key="index">
          <nuxt-link id="slider-item" :to="item.link" class="slider-item">
            <BaseImg :id="item.alt" :src="item.image" :alt="item.alt" lazy />
          </nuxt-link>
        </SwiperSlide>
      </Swiper>
      <div class="swiper-button-next" />
    </div>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper'
import { LIVE_CASINOES } from '@/resources/live-casino'
import BaseImg from '~/components/common/base-img.vue'
import { LOBBY_CASINO_URLS } from '~/config/page-url'
import HeaderSection from '@/components/common/header-section.vue'

const liveCasinoList = reactive(LIVE_CASINOES)

const swiperOption = reactive({
  slidesPerView: 5,
  slidesPerGroup: 1,
  spaceBetween: 30,
  centeredSlides: true,
  initialSlide: 3,
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  modules: [Navigation]
})

const handleSlideChange = (swiper) => {
  const slides = swiper.slides
  const activeIndex = swiper.activeIndex % slides.length
  const translateOffset = 16 // distance on figma

  slides.forEach((slide, index) => {
    const distanceFromActive = Math.abs(index - activeIndex)

    if (distanceFromActive === 0) {
      slide.style.transform = 'scale(1.289)'
    } else if (distanceFromActive === 1) {
      const isNextSlide = index > activeIndex
      slide.style.transform = `scale(1.138) translateX(${isNextSlide ? translateOffset : -translateOffset}px)`
    } else {
      const isLeftSide = index < activeIndex
      const translateAmount = (Math.min(distanceFromActive - 1, 2) * translateOffset) / 2
      slide.style.transform = `scale(0.99) translateX(${isLeftSide ? -translateAmount : translateAmount}px)`
    }
  })
}

onMounted(() => {
  const swiperInstance = document.querySelector('.swiper').swiper
  handleSlideChange(swiperInstance)
})
</script>

<style lang="scss" scoped src="assets/scss/components/desktop/pages/home/live-casino.scss"></style>
