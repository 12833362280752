import { LOBBY_GAME_URLS, SPORTS_URLS, PAGE_URLS } from '~/config/page-url'

export interface IGameItem {
  title: string
  button: string
  link: string
  imageUrl: string
  label?: string
  jackpot?: string
}

export interface IGameCenterItem {
  title: string
  description: string
  link: string
  imageUrl: string
  class?: string
  isReturn?: boolean
}

export const FAVORITE_GAMES: IGameItem[] = [
  {
    title: 'C-SPORTS',
    button: '',
    link: SPORTS_URLS.C_SPORT,
    imageUrl: '/assets/images/components/desktop/pages/home/game/lottie/c-sports.json'
  },
  {
    title: 'K-SPORTS',
    button: '',
    link: SPORTS_URLS.K_SPORT,
    imageUrl: '/assets/images/components/desktop/pages/home/game/lottie/k-sports.json',
    label: '/assets/images/components/desktop/pages/home/game/label-event.png'
  },
  {
    title: 'P-SPORTS',
    button: '',
    link: SPORTS_URLS.P_SPORT,
    imageUrl: '/assets/images/components/desktop/pages/home/game/lottie/p-sports.json'
  },
  {
    title: 'GAME BÀI',
    button: '',
    link: '/lobby/game-bai',
    imageUrl: '/assets/images/components/desktop/pages/home/game/lottie/game-bai.json'
  },
  {
    title: 'INGAME',
    button: '',
    link: '/lobby/ingame',
    imageUrl: '/assets/images/components/desktop/pages/home/game/lottie/ingame.json',
    jackpot: 'jackpotIngame'
  },
  {
    title: 'KENO',
    button: '',
    link: '/lobby/quick-game',
    imageUrl: '/assets/images/components/desktop/pages/home/game/lottie/keno.json',
    label: '/assets/images/components/desktop/pages/home/game/label-hoantran1.25.svg'
  },
  {
    title: 'NỔ HŨ',
    button: '',
    link: '/lobby/nohu',
    imageUrl: '/assets/images/components/desktop/pages/home/game/lottie/no-hu.json',
    jackpot: 'jackpotNohu'
  }
]

export const GAME_CENTER: IGameCenterItem[] = [
  {
    title: 'Cá Cược Thể thao',
    description: 'Trả cược nhanh chóng',
    link: PAGE_URLS.SPORT,
    imageUrl: '/assets/images/components/desktop/pages/home/game/sport.webp'
  },
  {
    title: 'Game Bài',
    description: 'Game đối kháng, chống vây',
    link: LOBBY_GAME_URLS.GAME_BAI,
    imageUrl: '/assets/images/components/desktop/pages/home/game/game-bai.webp'
  },
  {
    title: 'Table Game',
    description: 'Trò chơi cá cược công bằng',
    link: LOBBY_GAME_URLS.TABLE_GAME,
    imageUrl: '/assets/images/components/desktop/pages/home/game/table-game.webp'
  },
  {
    title: 'Quay Số',
    description: 'Đa dạng thể loại, cách chơi',
    link: LOBBY_GAME_URLS.QUAY_SO,
    imageUrl: '/assets/images/components/desktop/pages/home/game/quay-so.webp'
  },
  {
    title: 'Slots/ Nổ Hũ',
    description: 'Slots cổ điển & hiện đại',
    link: LOBBY_GAME_URLS.SLOTS_GAME,
    imageUrl: '/assets/images/components/desktop/pages/home/game/slots.webp'
  },
  {
    title: 'Bắn Cá',
    description: 'Giải trí đơn giản, tiền thưởng lớn',
    link: LOBBY_GAME_URLS.BAN_CA,
    imageUrl: '/assets/images/components/desktop/pages/home/game/ban-ca.webp'
  },
  {
    title: 'Game Nhanh',
    description: 'X500 lần tài khoản siêu nhanh',
    link: LOBBY_GAME_URLS.QUICK_GAMES,
    imageUrl: '/assets/images/components/desktop/pages/home/game/game-nhanh.webp'
  }
]
