<template>
  <nuxt-link id="game-center-item" :to="game.link" class="game-center-item" :class="className">
    <div class="game-center-item__box">
      <div class="game-center-item__box--image">
        <BaseImg :id="game.title" :src="game.imageUrl" class="game-list--top" lazy :alt="game.title" />
      </div>
      <div class="game-center-item__box--title">
        {{ game.title }}
      </div>
      <div class="game-center-item__box--des">
        {{ game.description }}
      </div>
    </div>
  </nuxt-link>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app'
import { ACCOUNT_URLS, PAGE_URLS } from '~/config/page-url'
import BaseImg from '~/components/common/base-img.vue'
import { IGameCenterItem } from '~/resources/game'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { currentUser, sumJackpot } = storeToRefs(store)
const router = useRouter()

withDefaults(defineProps<{ game: IGameCenterItem; className: string; isShowIcon?: Boolean }>(), {
  game: () => ({
    title: '',
    imageUrl: '',
    description: '',
    link: '',
    isReturn: false
  }),
  className: () => '',
  isShowIcon: () => false
})
</script>

<style scoped lang="scss" src="assets/scss/components/desktop/pages/home/game-center/game-center-item.scss"></style>
