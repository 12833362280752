<template>
  <div class="game-wrapper">
    <ul class="game-list desktop">
      <li v-for="(game, index) of centerGames" :key="index" :class="{ 'game-list--top': game.class === 'final' }">
        <GameItem :game="game" class-name="favorite" />
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import GameItem from '@/components/desktop/pages/home/game-center/game-center-item.vue'
import { GAME_CENTER, IGameCenterItem } from '@/resources/game'

const centerGames = reactive<IGameCenterItem[]>(GAME_CENTER)
</script>
<style scoped lang="scss" src="assets/scss/components/desktop/pages/home/game-center/list.scss"></style>
