interface LiveCasino {
  image: string
  link: string
  alt: string
}

export const LIVE_CASINOES: LiveCasino[] = [
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/ebet.webp',
    link: '/livecasino?partner=ebet',
    alt: 'ebet'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/b52.webp',
    link: '/livecasino?partner=b52',
    alt: 'b52'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/vivo.webp',
    link: '/livecasino?partner=vivo',
    alt: 'vivo'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/evolution.webp',
    link: '/livecasino?partner=evo',
    alt: 'evolution'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/go.webp',
    link: '/livecasino?partner=go',
    alt: 'go'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/rik.webp',
    link: '/livecasino?partner=rik',
    alt: 'rik'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/pragmatic.webp',
    link: '/livecasino?partner=pragmatic',
    alt: 'pragmatic'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/ezugi.webp',
    link: '/livecasino?partner=ezugi',
    alt: 'ezugi'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/hogaming.webp',
    link: '/livecasino?partner=hogaming',
    alt: 'hogaming'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/microgaming.webp',
    link: '/livecasino?partner=microgaming',
    alt: 'microgaming'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/mglive.webp',
    link: '/livecasino?partner=mg',
    alt: 'mglive'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/yeebet.webp',
    link: '/livecasino?partner=yeebet',
    alt: 'yeebet'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/iwin.webp',
    link: '/livecasino',
    alt: 'iwin'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/sexy-gaming.webp',
    link: '/livecasino',
    alt: 'sexy-gaming'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/dream-gaming.webp',
    link: '/livecasino',
    alt: 'dream-gaming'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/sa-gaming.webp',
    link: '/livecasino',
    alt: 'sa-gaming'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/fresh-deck.webp',
    link: '/livecasino',
    alt: 'fresh-deck'
  }
]
