<template>
  <div class="seo-hidden">
    <div class="seo-content__title" v-html="dataSeo?.content_title" />
    <div
      class="seo-content__description container-custom"
      :class="{ 'is-desktop': $device.isDesktop }"
      v-html="dataSeo?.content_description"
    />
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
const { $device } = useNuxtApp()
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { seoData } = storeToRefs(store)
const route = useRoute()

const dataSeo = computed(() => {
  if (route.path === '/') {
    return seoData.value.find((item) => item.alias === '/')
  }
  if (route.name === 'khuyen-mai-id') {
    return seoData.value.find((item) => item.alias === 'khuyen-mai')
  }
  return seoData.value.find((item) => item.alias === route.path.slice(1))
})
</script>

<style scoped lang="scss" src="assets/scss/components/common/seo-content.scss" />
