<template>
  <div class="promotion">
    <nuxt-link
      v-for="(item, index) in promotion"
      id="promotion-item"
      :key="index"
      :to="item.alias"
      class="promotion-item"
    >
      <BaseImg id="promotion" :src="item.img" alt="promotion" lazy />
      <div class="promotion-item__content">
        <h3 class="promotion-item__content--title" v-html="item.title" />
        <h3 class="promotion-item__content--desc" v-html="item.desc" />
      </div>
      <div class="detail-button">Chi tiết</div>
    </nuxt-link>
  </div>
</template>

<script setup>
import { homePromotion } from '~/constants/promotions'
const { promotion } = homePromotion()
</script>

<style scoped lang="scss">
@import 'assets/scss/components/desktop/pages/home/promotion-home/promotion.scss';
</style>
